import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/jenkins/workspace/canvas-site-deploy/canvas-site/src/components/MDXLayout.tsx";
import CKDocs from '@workday/canvas-kit-docs/dist/mdx/preview-react/status-indicator/StatusIndicator.mdx';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const TabPanel = makeShortcode("TabPanel");
const PackageInfo = makeShortcode("PackageInfo");
const NoticeCard = makeShortcode("NoticeCard");
const LegacyPatternLink = makeShortcode("LegacyPatternLink");
const InternalContent = makeShortcode("InternalContent");
const SideBySide = makeShortcode("SideBySide");
const Suggestion = makeShortcode("Suggestion");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TabPanel data-id="Web" mdxType="TabPanel">
      <PackageInfo github="/modules/preview-react/status-indicator" storybook="https://workday.github.io/canvas-kit/?path=/story/preview-status-indicator--basic" figma="https://www.figma.com/design/6Y3uHyGKnS8m0DzWBLeL73/Canvas-Web-v11?node-id=30877-34300&t=UhTeJ6F4tDSMVJmL-4" package="@workday/canvas-kit-preview-react" mdxType="PackageInfo" />
      <NoticeCard headingText="Status Indicator (Main) vs. Status Indicator (Preview)" linkText="Status Indicator (Main) Documentation" linkTo="https://workday.github.io/canvas-kit/?path=/docs/components-indicators-status-indicator--basic" mdxType="NoticeCard">
        <p>{`We recommend you use the Status Indicator in the Preview package
(`}<inlineCode parentName="p">{`@workday/canvas-kit-preview-react`}</inlineCode>{`) documented here on this page. The Status Indicator in the Main
package (`}<inlineCode parentName="p">{`@workday/canvas-kit-react`}</inlineCode>{`) will eventually be replaced with the Status Indicator in the
Preview package.`}</p>
      </NoticeCard>
      <h2 {...{
        "id": "anatomy"
      }}>{`Anatomy`}</h2>
      <p><span parentName="p" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "740px"
          }
        }}>{`
      `}<a parentName="span" {...{
            "className": "gatsby-resp-image-link",
            "href": "/static/372777c5c2d22eb8a312536ffb75d38a/536c7/status-indicator-anatomy.png",
            "style": {
              "display": "block"
            },
            "target": "_blank",
            "rel": "noopener"
          }}>{`
    `}<span parentName="a" {...{
              "className": "gatsby-resp-image-background-image",
              "style": {
                "paddingBottom": "18.91891891891892%",
                "position": "relative",
                "bottom": "0",
                "left": "0",
                "display": "block"
              }
            }}></span>{`
  `}<img parentName="a" {...{
              "className": "gatsby-resp-image-image",
              "alt": "Image of a Card with annotation markers.",
              "title": "Image of a Card with annotation markers.",
              "src": "/static/372777c5c2d22eb8a312536ffb75d38a/50383/status-indicator-anatomy.png",
              "srcSet": ["/static/372777c5c2d22eb8a312536ffb75d38a/1efb2/status-indicator-anatomy.png 370w", "/static/372777c5c2d22eb8a312536ffb75d38a/50383/status-indicator-anatomy.png 740w", "/static/372777c5c2d22eb8a312536ffb75d38a/536c7/status-indicator-anatomy.png 1480w"],
              "sizes": "(max-width: 740px) 100vw, 740px",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              },
              "loading": "lazy",
              "decoding": "async"
            }}></img>{`
  `}</a>{`
    `}</span></p>
      <ol>
        <li parentName="ol"><strong parentName="li">{`Text`}</strong>{`: Bold, single-line, title-case text is used to recognize a status quickly.`}</li>
        <li parentName="ol"><strong parentName="li">{`Background`}</strong>{`: Color is used to help users quickly recognize the meaning of a status across
applications. Each background has two emphasis variations to support both high and low emphasis
contrast (see Variations below).`}</li>
        <li parentName="ol"><strong parentName="li">{`System Icon`}</strong>{`: Optional visual to further emphasize and support status.`}</li>
      </ol>
      <h2 {...{
        "id": "usage-guidance"
      }}>{`Usage Guidance`}</h2>
      <ul>
        <li parentName="ul">{`A Status Indicator is a way of making an element on the page stand out to inform the user that
there is something special about it that warrants the user’s attention.`}</li>
        <li parentName="ul">{`Status Indicators are non-interactive visual cues that highlight a change in the system or task.
They are read-only, passive elements that should not take the place of an action or button.`}</li>
        <li parentName="ul">{`Although Status Indicators are commonly used to signal validation feedback or notifications, they
can also be used on their own, such as within a Table.`}</li>
        <li parentName="ul">{`Status Indicators have a max-width of 200px so that users can easily scan and recognize status
quickly. Status text should be short, direct, and preferable a single word.`}</li>
        <li parentName="ul">{`Status Indicator text should not wrap.`}</li>
        <li parentName="ul">{`In cases where truncation is necessary, an`}
          <LegacyPatternLink href="/patterns/overflow/#tab=usage&heading=truncation" mdxType="LegacyPatternLink">Overflow
Tooltip</LegacyPatternLink> can be used to reveal the full-text. In general, try to avoid relying
on overflow solutions like tooltips - instead, keep text concise to avoid truncation from
happening in the first place.
        </li>
        <li parentName="ul">{`Combine background color variation with logical status text.`}</li>
        <li parentName="ul">{`When competing with other visual labelling elements, status indicators can be distracting. Use
them in moderation, and consider how many (if any) indicators should be used in your design.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use"
      }}>{`When To Use`}</h3>
      <ul>
        <li parentName="ul">{`To attract user attention to a particular piece of content or UI element whose status has changed
or may change in the future.`}</li>
        <li parentName="ul">{`Use low-emphasis indicators in instances where they may dominate the screen, such as in a table
when they appear alongside many other Indicators.`}</li>
        <li parentName="ul">{`Use high-emphasis Status Indicators sparingly. Reserve these to pair with headers or page section
titles.`}</li>
        <li parentName="ul">{`Reserve transparent Status Indicators to communicate status on top of imagery and video.`}</li>
      </ul>
      <h3 {...{
        "id": "when-to-use-something-else"
      }}>{`When To Use Something Else`}</h3>
      <ul>
        <li parentName="ul">{`If one or two words is not enough to convey the status, consider using body text or a header with
detail text to communicate the status.`}</li>
        <li parentName="ul">{`If a status is less important to the user or task, consider using body text or a header to
communicate the status.`}</li>
        <li parentName="ul">{`Status Indicators are not interactive components. If a status needs to be interactive, consider
using a `}<a parentName="li" {...{
            "href": "/components/buttons/hyperlink"
          }}>{`Hyperlink`}</a>{` or
`}<a parentName="li" {...{
            "href": "/components/buttons/button#heading=examples"
          }}>{`Tertiary Button`}</a>{`.`}</li>
      </ul>
      <h2 {...{
        "id": "variations"
      }}>{`Variations`}</h2>
      <p>{`Status Indicators have a background color to help users recognize the meaning of a status across
applications. Each indicator background type has two variations, one to support both high and low
emphasis indicators. Keep in mind that Status Indicators can increase the amount of visual noise or
add unwanted emphasis when used repetitively. Take this into consideration when selecting your
background variation.`}</p>
      <p>{`The examples below outline the suggested purpose of each indicator color and variation.`}</p>
      <CKDocs mdxType="CKDocs" />
      <h2 {...{
        "id": "accessibility-guidelines"
      }}>{`Accessibility Guidelines`}</h2>
      <h3 {...{
        "id": "how-status-indicators-impact-the-accessible-experience"
      }}>{`How Status Indicators Impact the Accessible Experience?`}</h3>
      <p>{`Status Indicators do not have any keyboard interaction because they represent static text on the UI.
However, when the text inside Status Indicator is truncated (overflow), users must be able to
display the full text using a keyboard only.`}</p>
      <p>{`When using icons in Status Indicators, the icons may be considered decorative in most cases and will
not require any text alternatives for screen readers. In cases where the icon communicates something
about the status that is not conveyed by the text of the status, then the icon will need to be
augmented with a text alternative.`}</p>
      <h3 {...{
        "id": "keyboard-interaction"
      }}>{`Keyboard Interaction`}</h3>
      <p>{`When the Status Indicator text overflows, it must have a focus indicator that is highly visible
against the background and against the non-focused state. Refer to
`}<a parentName="p" {...{
          "href": "/guidelines/accessibility/accessible-color"
        }}>{`Accessible Colors`}</a>{` for more information.`}</p>
      <p>{`Status Indicator with overflow must support the following keyboard interactions:`}</p>
      <ul>
        <li parentName="ul"><inlineCode parentName="li">{`Tab`}</inlineCode>{`: focus the status indicator, tooltip displays when status indicator is focused`}</li>
        <li parentName="ul"><inlineCode parentName="li">{`Esc`}</inlineCode>{`: dismiss tooltip`}</li>
      </ul>
      <h3 {...{
        "id": "design-annotations-needed"
      }}>{`Design Annotations Needed`}</h3>
      <p>{`No annotations required for Status Indicators`}</p>
      <h3 {...{
        "id": "implementation-markup-needed"
      }}>{`Implementation Markup Needed`}</h3>
      <ul>
        <li parentName="ul">{`Multiple Status Indicators can be grouped together using unordered list markup, `}<inlineCode parentName="li">{`<ul>`}</inlineCode>{` and `}<inlineCode parentName="li">{`<li>`}</inlineCode>{`
elements.`}</li>
        <li parentName="ul">{`Status Indicator with overflow must have a `}<inlineCode parentName="li">{`tabindex`}</inlineCode>{` attribute and an
`}<a parentName="li" {...{
            "href": "/components/popups/tooltip#tooltips-on-overflowing-content"
          }}>{`OverflowTooltip`}</a>{` to display the full
text on hover and keyboard focus.`}</li>
        <li parentName="ul">{`[Included in component]`}{` Decorative `}<inlineCode parentName="li">{`<svg>`}</inlineCode>{` icons are hidden from assistive technology with
`}<inlineCode parentName="li">{`role=“presentation”`}</inlineCode>{` and `}<inlineCode parentName="li">{`focusable=“false”`}</inlineCode></li>
      </ul>
      <h2 {...{
        "id": "content-guidelines"
      }}>{`Content Guidelines`}</h2>
      <ul>
        <li parentName="ul">{`Keep status text short, direct, and preferably a single word. Keep in mind that Status Indicator
containers have a maximum width of 200px before text truncation begins.`}</li>
        <li parentName="ul">{`Avoid creating status labels that require more characters than the max-width allows for.`}</li>
        <li parentName="ul">{`Status Indicator text should never wrap.`}</li>
        <li parentName="ul">{`Use status labels that are relatable or familiar to your user and the task at hand.`}</li>
        <li parentName="ul">{`When writing content for Status Indicators, refer to our `}<a parentName="li" {...{
            "href": "/guidelines/content/overview"
          }}>{`Content Style Guide`}</a>{`.`}</li>
      </ul>
    </TabPanel>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="iOS" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7736%3A5849&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-1"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/470aea93c264095b1d8154dd63aaf469/536c7/component-anatomy-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.64864864864865%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAcElEQVR42mP4jw/8g9Kfvv///+wDVOwfXi0MmIYANXz69v/Ht9//n3/88//tz///f7x89//luav/3/z69P/nr5+kGfjvL9DA8/f/P7j38X//wY//V5z/AXTcy/8Ljq74v+jE6v9P3j+D2vuPSBdSCABiozkFQG953gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the Status Indicator",
                "title": "The basic elements of the Status Indicator",
                "src": "/static/470aea93c264095b1d8154dd63aaf469/50383/component-anatomy-statusindicator-mobile.png",
                "srcSet": ["/static/470aea93c264095b1d8154dd63aaf469/1efb2/component-anatomy-statusindicator-mobile.png 370w", "/static/470aea93c264095b1d8154dd63aaf469/50383/component-anatomy-statusindicator-mobile.png 740w", "/static/470aea93c264095b1d8154dd63aaf469/536c7/component-anatomy-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Text`}</strong>{`: Bold, single-line, title-case text is used to recognize a status quickly.`}</li>
          <li parentName="ol"><strong parentName="li">{`Background`}</strong>{`: Color is used to help users quickly recognize the meaning of a status across
applications. Each background has two color variations to support both high and low emphasis
indicators (see Variations below).`}</li>
          <li parentName="ol"><strong parentName="li">{`System Icon:`}</strong>{` Optional visual to further emphasize and support status.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-1"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`A Status Indicator is a way of making an element on the page stand out to inform the user that
there is a status associated with it that warrants the user’s attention.`}</li>
          <li parentName="ul">{`Status Indicators are non-interactive visual cues that highlight a change in the system or task.
They are read-only, passive elements that should not take the place of an action or button.`}</li>
          <li parentName="ul">{`Status Indicator text should not wrap and instead stretch to fill the container or screen margins.
When the max-width is reached, text is truncated. Try to avoid truncation from happening in the
first place by keeping text short, direct, and preferably a single word.`}</li>
          <li parentName="ul">{`Match semantic background colors with appropriate status text. For example, use red to indicate
missing actions like “Not Submitted” or green for completed actions like “Published” or
“Complete”.`}</li>
          <li parentName="ul">{`When competing with other visual labeling elements, Status Indicators can be distracting. Use them
in moderation, and consider how many (if any) indicators should be used in your design.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-1"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To attract user attention to a particular piece of content or UI element whose status has changed
or may change in the future.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-1"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If one or two words is not enough to convey the status, consider using body text or a header with
detail text to communicate the status.`}</li>
          <li parentName="ul">{`If a status is less important to the user or task, consider using body text or a header to
communicate the status.`}</li>
          <li parentName="ul">{`Consider using a read-only `}<a parentName="li" {...{
              "href": "/components/indicators/pill#tab=ios"
            }}>{`Pill`}</a>{` to communicate additional
metadata tags about an object, as opposed to a status.`}</li>
          <li parentName="ul">{`Status Indicators are not interactive components. If a status needs to be interactive, consider
using a Hyperlink or
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=ios&heading=tertiary-button"
            }}>{`Tertiary Button`}</a>{`.`}</li>
        </ul>
        <h3 {...{
          "id": "text-overflow"
        }}>{`Text Overflow`}</h3>
        <p>{`Text in Status Indicators should never wrap to a second line. Instead, truncate Status Indicator
text if they reach the screen/container margins. In general, try to avoid this by keeping text
concise.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate Status Indicator text if they reach the screen or container margins." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/bab7a07c2fd7481df28d25fb47653323/82158/component-do-overflow-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing truncated Status Indicators within a card",
                    "title": "Image showing truncated Status Indicators within a card",
                    "src": "/static/bab7a07c2fd7481df28d25fb47653323/82158/component-do-overflow-statusindicator-mobile.png",
                    "srcSet": ["/static/bab7a07c2fd7481df28d25fb47653323/1efb2/component-do-overflow-statusindicator-mobile.png 370w", "/static/bab7a07c2fd7481df28d25fb47653323/82158/component-do-overflow-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Wrap Status Indicator text to multiple lines" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/4658efb9fa92bef7e3abb97d1bd9387a/82158/component-dont-overflow-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing text wrapping in Status Indicators",
                    "title": "Image showing text wrapping in Status Indicators",
                    "src": "/static/4658efb9fa92bef7e3abb97d1bd9387a/82158/component-dont-overflow-statusindicator-mobile.png",
                    "srcSet": ["/static/4658efb9fa92bef7e3abb97d1bd9387a/1efb2/component-dont-overflow-statusindicator-mobile.png 370w", "/static/4658efb9fa92bef7e3abb97d1bd9387a/82158/component-dont-overflow-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "keep-labels-concise"
        }}>{`Keep Labels Concise`}</h3>
        <p>{`Try to keep Status Indicator labels short and to the point. Rule of thumb - keep it under three
words.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Keep Status Indicator text short and to the point." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/b07fdc58354997e1d19d876f3a27b613/82158/component-do-content-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing a concise Status Indicator within a card.",
                    "title": "Image showing a concise Status Indicator within a card.",
                    "src": "/static/b07fdc58354997e1d19d876f3a27b613/82158/component-do-content-statusindicator-mobile.png",
                    "srcSet": ["/static/b07fdc58354997e1d19d876f3a27b613/1efb2/component-do-content-statusindicator-mobile.png 370w", "/static/b07fdc58354997e1d19d876f3a27b613/82158/component-do-content-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use overly wordy text to communicate a point." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/967c23e5a1335bd02fa8682d679bbb93/82158/component-dont-content-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing a wordy Status Indicator within a Card.",
                    "title": "Image showing a wordy Status Indicator within a Card.",
                    "src": "/static/967c23e5a1335bd02fa8682d679bbb93/82158/component-dont-content-statusindicator-mobile.png",
                    "srcSet": ["/static/967c23e5a1335bd02fa8682d679bbb93/1efb2/component-dont-content-statusindicator-mobile.png 370w", "/static/967c23e5a1335bd02fa8682d679bbb93/82158/component-dont-content-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "variations-1"
        }}>{`Variations`}</h2>
        <p>{`Status Indicators have a background color to help users recognize the meaning of a status across
applications. Each indicator background type has two variations, one to support both high and low
emphasis indicators. Keep in mind that Status Indicators can increase the amount of visual
distractions or add unwanted emphasis when used repetitively. Take this into consideration when
selecting your background variation.`}</p>
        <p>{`The examples below outline the suggested purpose of each indicator color and variation.`}</p>
        <h3 {...{
          "id": "high-emphasis-status-indicators"
        }}>{`High Emphasis Status Indicators`}</h3>
        <p>{`Reserve high-emphasis indicators for when there are few Status Indicators in the UI and more
emphasis is required to draw attention.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/efb75a0833d380e9e59998069a5cfb87/536c7/component-variation-highem-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAgUlEQVR42q3PvQrCMBiF4d7/tYibuFik6iRio7REtMEuzVf/BrVWIaSvijgJTj3DOz5wAlpe8M7G5EznS0QEZR0dVbHaebTV9JIhx3PJw4RczQB3sNzGEb6uP0LT/ILZNmemEqQU4sLRXVSs95600PTTiNMLvGchly84Gf0H27z8BJ2VNDZNyBaxAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing different color variations of high emphasis indicators",
                "title": "Image showing different color variations of high emphasis indicators",
                "src": "/static/efb75a0833d380e9e59998069a5cfb87/50383/component-variation-highem-statusindicator-mobile.png",
                "srcSet": ["/static/efb75a0833d380e9e59998069a5cfb87/1efb2/component-variation-highem-statusindicator-mobile.png 370w", "/static/efb75a0833d380e9e59998069a5cfb87/50383/component-variation-highem-statusindicator-mobile.png 740w", "/static/efb75a0833d380e9e59998069a5cfb87/536c7/component-variation-highem-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "low-emphasis-status-indicators"
        }}>{`Low Emphasis Status Indicators`}</h3>
        <p>{`Use low-emphasis indicators in instances where they may dominate the screen, such as in a table when
they appear alongside many other indicators.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/136a9f23edd66a3a2efd06ebaae9c6a9/536c7/component-variation-lowem-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAaElEQVR42q3PwQ6CMBCEYd7/JU04CAjbWFrZds32F6JXPDHJzPFLpuPidMeklJkloKo81elDJZdGssxjE95u1HXcO+BWsRBo7udgTIlpFrYfeFsKqzbyDo6vBTvAOFDi/QuK/AevvPwBYQI7RWn9+AMAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing different color variations of low emphasis indicators",
                "title": "Image showing different color variations of low emphasis indicators",
                "src": "/static/136a9f23edd66a3a2efd06ebaae9c6a9/50383/component-variation-lowem-statusindicator-mobile.png",
                "srcSet": ["/static/136a9f23edd66a3a2efd06ebaae9c6a9/1efb2/component-variation-lowem-statusindicator-mobile.png 370w", "/static/136a9f23edd66a3a2efd06ebaae9c6a9/50383/component-variation-lowem-statusindicator-mobile.png 740w", "/static/136a9f23edd66a3a2efd06ebaae9c6a9/536c7/component-variation-lowem-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "icons"
        }}>{`Icons`}</h3>
        <p>{`Icons can be displayed to the left of high or low emphasis Status Indicator text.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2e5aea277ab83ed10f4e28dbe03aedce/536c7/component-variation-icons-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.7027027027027%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAyklEQVR42q2Qy0rDQBhG+/5PoRuXUqygWAi1YrW2hbbxAjUNQ1AhoZfMTEzSf5Ic0V1xUReexVl9nMXX4p9pfUtFb4ynPrPHZ+J1Sm8ptP2MO1WxTEIufY+bYEym3ykX5+iXDm6jKB6G5LfXNCL7wddQ0b8fMRhN+Eg2nD0VHA013kII14rT+QXdYIDZRtjZCavJMbIKyPo9rHf1Oyji0MaSaoNzFbasSMyO9NP9jIxk6NJS1w7JtxQmpq52uDxHjIGm2Q8epPn7h19GTX4KT1i7lAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing Status Indicators with a leading icon",
                "title": "Image showing Status Indicators with a leading icon",
                "src": "/static/2e5aea277ab83ed10f4e28dbe03aedce/50383/component-variation-icons-statusindicator-mobile.png",
                "srcSet": ["/static/2e5aea277ab83ed10f4e28dbe03aedce/1efb2/component-variation-icons-statusindicator-mobile.png 370w", "/static/2e5aea277ab83ed10f4e28dbe03aedce/50383/component-variation-icons-statusindicator-mobile.png 740w", "/static/2e5aea277ab83ed10f4e28dbe03aedce/536c7/component-variation-icons-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "transparent-status-indicators"
        }}>{`Transparent Status Indicators`}</h3>
        <p>{`Reserve transparent Status Indicators to communicate status on top of imagery and video.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/251dead57320b5288178d480f7d36b72/536c7/component-variation-transparent-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAASElEQVR42q2PsQ3AMAgEvf9QUAPCTIFnMA1vJRsk8hdXnU76gcsbD+acMDOstUBEEBFU1St09/dgRMDdkZlgZqgq9t7/gzcvH3KvOPF98qg9AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing Status Indicators",
                "title": "Image showing Status Indicators",
                "src": "/static/251dead57320b5288178d480f7d36b72/50383/component-variation-transparent-statusindicator-mobile.png",
                "srcSet": ["/static/251dead57320b5288178d480f7d36b72/1efb2/component-variation-transparent-statusindicator-mobile.png 370w", "/static/251dead57320b5288178d480f7d36b72/50383/component-variation-transparent-statusindicator-mobile.png 740w", "/static/251dead57320b5288178d480f7d36b72/536c7/component-variation-transparent-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines"
        }}>{`API Guidelines`}</h2>
        <p>{`Coming soon!`}</p>
        <h2 {...{
          "id": "accessibility-guidelines-1"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-1"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Keep status text short, direct, and preferably a single word. Keep in mind that Status Indicator
containers can stretch to screen or container margins before truncation begins.`}</li>
          <li parentName="ul">{`Avoid creating status labels that require more characters than the container allows.`}</li>
          <li parentName="ul">{`Status Indicator text should never wrap.`}</li>
          <li parentName="ul">{`Use status labels that are relatable or familiar to your user and the task at hand.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>
    <InternalContent mdxType="InternalContent">
      <TabPanel data-id="Android" mdxType="TabPanel">
        <PackageInfo figma="https://www.figma.com/file/MtaQChthxt2KIX8oveeWQw/Canvas-Mobile?node-id=7736%3A5849&t=pyJVEluqEmBHj6mL-4" mdxType="PackageInfo" />
        <h2 {...{
          "id": "anatomy-2"
        }}>{`Anatomy`}</h2>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/470aea93c264095b1d8154dd63aaf469/536c7/component-anatomy-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "18.64864864864865%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAcElEQVR42mP4jw/8g9Kfvv///+wDVOwfXi0MmIYANXz69v/Ht9//n3/88//tz///f7x89//luav/3/z69P/nr5+kGfjvL9DA8/f/P7j38X//wY//V5z/AXTcy/8Ljq74v+jE6v9P3j+D2vuPSBdSCABiozkFQG953gAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "The basic elements of the Status Indicator",
                "title": "The basic elements of the Status Indicator",
                "src": "/static/470aea93c264095b1d8154dd63aaf469/50383/component-anatomy-statusindicator-mobile.png",
                "srcSet": ["/static/470aea93c264095b1d8154dd63aaf469/1efb2/component-anatomy-statusindicator-mobile.png 370w", "/static/470aea93c264095b1d8154dd63aaf469/50383/component-anatomy-statusindicator-mobile.png 740w", "/static/470aea93c264095b1d8154dd63aaf469/536c7/component-anatomy-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <ol>
          <li parentName="ol"><strong parentName="li">{`Text`}</strong>{`: Bold, single-line, title-case text is used to recognize a status quickly.`}</li>
          <li parentName="ol"><strong parentName="li">{`Background`}</strong>{`: Color is used to help users quickly recognize the meaning of a status across
applications. Each background has two color variations to support both high and low emphasis
indicators (see Variations below).`}</li>
          <li parentName="ol"><strong parentName="li">{`System Icon:`}</strong>{` Optional visual to further emphasize and support status.`}</li>
        </ol>
        <h2 {...{
          "id": "usage-guidance-2"
        }}>{`Usage Guidance`}</h2>
        <ul>
          <li parentName="ul">{`A Status Indicator is a way of making an element on the page stand out to inform the user that
there is a status associated with it that warrants the user’s attention.`}</li>
          <li parentName="ul">{`Status Indicators are non-interactive visual cues that highlight a change in the system or task.
They are read-only, passive elements that should not take the place of an action or button.`}</li>
          <li parentName="ul">{`Status Indicator text should not wrap and instead stretch to fill the container or screen margins.
When the max-width is reached, text is truncated. Try to avoid truncation from happening in the
first place by keeping text short, direct, and preferably a single word.`}</li>
          <li parentName="ul">{`Match semantic background colors with appropriate status text. For example, use red to indicate
missing actions like “Not Submitted” or green for completed actions like “Published” or
“Complete”.`}</li>
          <li parentName="ul">{`When competing with other visual labeling elements, Status Indicators can be distracting. Use them
in moderation, and consider how many (if any) indicators should be used in your design.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-2"
        }}>{`When to Use`}</h3>
        <ul>
          <li parentName="ul">{`To attract user attention to a particular piece of content or UI element whose status has changed
or may change in the future.`}</li>
        </ul>
        <h3 {...{
          "id": "when-to-use-something-else-2"
        }}>{`When to Use Something Else`}</h3>
        <ul>
          <li parentName="ul">{`If one or two words is not enough to convey the status, consider using body text or a header with
detail text to communicate the status.`}</li>
          <li parentName="ul">{`If a status is less important to the user or task, consider using body text or a header to
communicate the status.`}</li>
          <li parentName="ul">{`Consider using a read-only `}<a parentName="li" {...{
              "href": "/components/indicators/pill#tab=android"
            }}>{`Pill`}</a>{` to communicate
additional metadata tags about an object, as opposed to a status.`}</li>
          <li parentName="ul">{`Status Indicators are not interactive components. If a status needs to be interactive, consider
using a Hyperlink or
`}<a parentName="li" {...{
              "href": "/components/buttons/button#tab=android&heading=tertiary-button-2"
            }}>{`Tertiary Button`}</a>{`.`}</li>
        </ul>
        <h3 {...{
          "id": "text-overflow-1"
        }}>{`Text Overflow`}</h3>
        <p>{`Text in Status Indicators should never wrap to a second line. Instead, truncate Status Indicator
text if they reach the screen/container margins. In general, try to avoid this by keeping text
concise.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Truncate Status Indicator text if they reach the screen or container margins." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/bab7a07c2fd7481df28d25fb47653323/82158/component-do-overflow-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing truncated Status Indicators within a card",
                    "title": "Image showing truncated Status Indicators within a card",
                    "src": "/static/bab7a07c2fd7481df28d25fb47653323/82158/component-do-overflow-statusindicator-mobile.png",
                    "srcSet": ["/static/bab7a07c2fd7481df28d25fb47653323/1efb2/component-do-overflow-statusindicator-mobile.png 370w", "/static/bab7a07c2fd7481df28d25fb47653323/82158/component-do-overflow-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Wrap Status Indicator text to multiple lines" mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/4658efb9fa92bef7e3abb97d1bd9387a/82158/component-dont-overflow-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing text wrapping in Status Indicators",
                    "title": "Image showing text wrapping in Status Indicators",
                    "src": "/static/4658efb9fa92bef7e3abb97d1bd9387a/82158/component-dont-overflow-statusindicator-mobile.png",
                    "srcSet": ["/static/4658efb9fa92bef7e3abb97d1bd9387a/1efb2/component-dont-overflow-statusindicator-mobile.png 370w", "/static/4658efb9fa92bef7e3abb97d1bd9387a/82158/component-dont-overflow-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h3 {...{
          "id": "keep-labels-concise-1"
        }}>{`Keep Labels Concise`}</h3>
        <p>{`Try to keep Status Indicator labels short and to the point. Rule of thumb - keep it under three
words.`}</p>
        <SideBySide mdxType="SideBySide">
          <Suggestion status="do" guidance="Keep Status Indicator text short and to the point." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/b07fdc58354997e1d19d876f3a27b613/82158/component-do-content-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing a concise Status Indicator within a card.",
                    "title": "Image showing a concise Status Indicator within a card.",
                    "src": "/static/b07fdc58354997e1d19d876f3a27b613/82158/component-do-content-statusindicator-mobile.png",
                    "srcSet": ["/static/b07fdc58354997e1d19d876f3a27b613/1efb2/component-do-content-statusindicator-mobile.png 370w", "/static/b07fdc58354997e1d19d876f3a27b613/82158/component-do-content-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
          <Suggestion status="error" guidance="Use overly wordy text to communicate a point." mdxType="Suggestion">
            <p><span parentName="p" {...{
                "className": "gatsby-resp-image-wrapper",
                "style": {
                  "position": "relative",
                  "display": "block",
                  "marginLeft": "auto",
                  "marginRight": "auto",
                  "maxWidth": "696px"
                }
              }}>{`
      `}<a parentName="span" {...{
                  "className": "gatsby-resp-image-link",
                  "href": "/static/967c23e5a1335bd02fa8682d679bbb93/82158/component-dont-content-statusindicator-mobile.png",
                  "style": {
                    "display": "block"
                  },
                  "target": "_blank",
                  "rel": "noopener"
                }}>{`
    `}<span parentName="a" {...{
                    "className": "gatsby-resp-image-background-image",
                    "style": {
                      "paddingBottom": "45.94594594594595%",
                      "position": "relative",
                      "bottom": "0",
                      "left": "0",
                      "display": "block"
                    }
                  }}></span>{`
  `}<img parentName="a" {...{
                    "className": "gatsby-resp-image-image",
                    "alt": "Image showing a wordy Status Indicator within a Card.",
                    "title": "Image showing a wordy Status Indicator within a Card.",
                    "src": "/static/967c23e5a1335bd02fa8682d679bbb93/82158/component-dont-content-statusindicator-mobile.png",
                    "srcSet": ["/static/967c23e5a1335bd02fa8682d679bbb93/1efb2/component-dont-content-statusindicator-mobile.png 370w", "/static/967c23e5a1335bd02fa8682d679bbb93/82158/component-dont-content-statusindicator-mobile.png 696w"],
                    "sizes": "(max-width: 696px) 100vw, 696px",
                    "style": {
                      "width": "100%",
                      "height": "100%",
                      "margin": "0",
                      "verticalAlign": "middle",
                      "position": "absolute",
                      "top": "0",
                      "left": "0"
                    },
                    "loading": "lazy",
                    "decoding": "async"
                  }}></img>{`
  `}</a>{`
    `}</span></p>
          </Suggestion>
        </SideBySide>
        <h2 {...{
          "id": "variations-2"
        }}>{`Variations`}</h2>
        <p>{`Status Indicators have a background color to help users recognize the meaning of a status across
applications. Each indicator background type has two variations, one to support both high and low
emphasis indicators. Keep in mind that Status Indicators can increase the amount of visual
distractions or add unwanted emphasis when used repetitively. Take this into consideration when
selecting your background variation.`}</p>
        <p>{`The examples below outline the suggested purpose of each indicator color and variation.`}</p>
        <h3 {...{
          "id": "high-emphasis-status-indicators-1"
        }}>{`High Emphasis Status Indicators`}</h3>
        <p>{`Reserve high-emphasis indicators for when there are few Status Indicators in the UI and more
emphasis is required to draw attention.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/efb75a0833d380e9e59998069a5cfb87/536c7/component-variation-highem-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAgUlEQVR42q3PvQrCMBiF4d7/tYibuFik6iRio7REtMEuzVf/BrVWIaSvijgJTj3DOz5wAlpe8M7G5EznS0QEZR0dVbHaebTV9JIhx3PJw4RczQB3sNzGEb6uP0LT/ILZNmemEqQU4sLRXVSs95600PTTiNMLvGchly84Gf0H27z8BJ2VNDZNyBaxAAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing different color variations of high emphasis indicators",
                "title": "Image showing different color variations of high emphasis indicators",
                "src": "/static/efb75a0833d380e9e59998069a5cfb87/50383/component-variation-highem-statusindicator-mobile.png",
                "srcSet": ["/static/efb75a0833d380e9e59998069a5cfb87/1efb2/component-variation-highem-statusindicator-mobile.png 370w", "/static/efb75a0833d380e9e59998069a5cfb87/50383/component-variation-highem-statusindicator-mobile.png 740w", "/static/efb75a0833d380e9e59998069a5cfb87/536c7/component-variation-highem-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "low-emphasis-status-indicators-1"
        }}>{`Low Emphasis Status Indicators`}</h3>
        <p>{`Use low-emphasis indicators in instances where they may dominate the screen, such as in a table when
they appear alongside many other indicators.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/136a9f23edd66a3a2efd06ebaae9c6a9/536c7/component-variation-lowem-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAaElEQVR42q3PwQ6CMBCEYd7/JU04CAjbWFrZds32F6JXPDHJzPFLpuPidMeklJkloKo81elDJZdGssxjE95u1HXcO+BWsRBo7udgTIlpFrYfeFsKqzbyDo6vBTvAOFDi/QuK/AevvPwBYQI7RWn9+AMAAAAASUVORK5CYII=')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing different color variations of low emphasis indicators",
                "title": "Image showing different color variations of low emphasis indicators",
                "src": "/static/136a9f23edd66a3a2efd06ebaae9c6a9/50383/component-variation-lowem-statusindicator-mobile.png",
                "srcSet": ["/static/136a9f23edd66a3a2efd06ebaae9c6a9/1efb2/component-variation-lowem-statusindicator-mobile.png 370w", "/static/136a9f23edd66a3a2efd06ebaae9c6a9/50383/component-variation-lowem-statusindicator-mobile.png 740w", "/static/136a9f23edd66a3a2efd06ebaae9c6a9/536c7/component-variation-lowem-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "icons-1"
        }}>{`Icons`}</h3>
        <p>{`Icons can be displayed to the left of high or low emphasis Status Indicator text.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/2e5aea277ab83ed10f4e28dbe03aedce/536c7/component-variation-icons-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "22.7027027027027%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAYAAABFA8wzAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAyklEQVR42q2Qy0rDQBhG+/5PoRuXUqygWAi1YrW2hbbxAjUNQ1AhoZfMTEzSf5Ic0V1xUReexVl9nMXX4p9pfUtFb4ynPrPHZ+J1Sm8ptP2MO1WxTEIufY+bYEym3ykX5+iXDm6jKB6G5LfXNCL7wddQ0b8fMRhN+Eg2nD0VHA013kII14rT+QXdYIDZRtjZCavJMbIKyPo9rHf1Oyji0MaSaoNzFbasSMyO9NP9jIxk6NJS1w7JtxQmpq52uDxHjIGm2Q8epPn7h19GTX4KT1i7lAAAAABJRU5ErkJggg==')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing Status Indicators with a leading icon",
                "title": "Image showing Status Indicators with a leading icon",
                "src": "/static/2e5aea277ab83ed10f4e28dbe03aedce/50383/component-variation-icons-statusindicator-mobile.png",
                "srcSet": ["/static/2e5aea277ab83ed10f4e28dbe03aedce/1efb2/component-variation-icons-statusindicator-mobile.png 370w", "/static/2e5aea277ab83ed10f4e28dbe03aedce/50383/component-variation-icons-statusindicator-mobile.png 740w", "/static/2e5aea277ab83ed10f4e28dbe03aedce/536c7/component-variation-icons-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h3 {...{
          "id": "transparent-status-indicators-1"
        }}>{`Transparent Status Indicators`}</h3>
        <p>{`Reserve transparent Status Indicators to communicate status on top of imagery and video.`}</p>
        <p><span parentName="p" {...{
            "className": "gatsby-resp-image-wrapper",
            "style": {
              "position": "relative",
              "display": "block",
              "marginLeft": "auto",
              "marginRight": "auto",
              "maxWidth": "740px"
            }
          }}>{`
      `}<a parentName="span" {...{
              "className": "gatsby-resp-image-link",
              "href": "/static/251dead57320b5288178d480f7d36b72/536c7/component-variation-transparent-statusindicator-mobile.png",
              "style": {
                "display": "block"
              },
              "target": "_blank",
              "rel": "noopener"
            }}>{`
    `}<span parentName="a" {...{
                "className": "gatsby-resp-image-background-image",
                "style": {
                  "paddingBottom": "17.837837837837835%",
                  "position": "relative",
                  "bottom": "0",
                  "left": "0",
                  "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAECAYAAACOXx+WAAAACXBIWXMAABYlAAAWJQFJUiTwAAAASElEQVR42q2PsQ3AMAgEvf9QUAPCTIFnMA1vJRsk8hdXnU76gcsbD+acMDOstUBEEBFU1St09/dgRMDdkZlgZqgq9t7/gzcvH3KvOPF98qg9AAAAAElFTkSuQmCC')",
                  "backgroundSize": "cover",
                  "display": "block"
                }
              }}></span>{`
  `}<img parentName="a" {...{
                "className": "gatsby-resp-image-image",
                "alt": "Image showing usage of tranparent Status Indicators",
                "title": "Image showing usage of tranparent Status Indicators",
                "src": "/static/251dead57320b5288178d480f7d36b72/50383/component-variation-transparent-statusindicator-mobile.png",
                "srcSet": ["/static/251dead57320b5288178d480f7d36b72/1efb2/component-variation-transparent-statusindicator-mobile.png 370w", "/static/251dead57320b5288178d480f7d36b72/50383/component-variation-transparent-statusindicator-mobile.png 740w", "/static/251dead57320b5288178d480f7d36b72/536c7/component-variation-transparent-statusindicator-mobile.png 1480w"],
                "sizes": "(max-width: 740px) 100vw, 740px",
                "style": {
                  "width": "100%",
                  "height": "100%",
                  "margin": "0",
                  "verticalAlign": "middle",
                  "position": "absolute",
                  "top": "0",
                  "left": "0"
                },
                "loading": "lazy",
                "decoding": "async"
              }}></img>{`
  `}</a>{`
    `}</span></p>
        <h2 {...{
          "id": "api-guidelines-1"
        }}>{`API Guidelines`}</h2>
        <h3 {...{
          "id": "component-definition"
        }}>{`Component Definition`}</h3>
        <pre><code parentName="pre" {...{
            "className": "language-kotlin"
          }}>{`@Composable
fun StatusIndicatorUiComponent(
    modifier: Modifier = Modifier,
    label: String,
    type: StatusIndicatorType,
    emphasis: StatusIndicatorEmphasis = StatusIndicatorEmphasis.HIGH, icon: @Composable() -&gt; [Unit]
`}</code></pre>
        <p>{`Status indicator ui component`}</p>
        <h3 {...{
          "id": "parameters"
        }}>{`Parameters`}</h3>
        <table>
          <thead parentName="table">
            <tr parentName="thead">
              <th parentName="tr" {...{
                "align": null
              }}>{`Name`}</th>
              <th parentName="tr" {...{
                "align": null
              }}>{`Description`}</th>
            </tr>
          </thead>
          <tbody parentName="table">
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`label`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Text shown on the status indicator`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`type`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines the type/color of the status indicator. Color options: Gray, Orange, Blue, Green, Red, Transparent`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`emphasis`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Determines whether the status indicator is high or low emphasis`}</td>
            </tr>
            <tr parentName="tbody">
              <td parentName="tr" {...{
                "align": null
              }}>{`icon`}</td>
              <td parentName="tr" {...{
                "align": null
              }}>{`Leading icon to be placed in the status indicator`}</td>
            </tr>
          </tbody>
        </table>
        <h2 {...{
          "id": "accessibility-guidelines-2"
        }}>{`Accessibility Guidelines`}</h2>
        <p>{`Refreshed mobile guidelines will be coming soon!`}</p>
        <h2 {...{
          "id": "content-guidelines-2"
        }}>{`Content Guidelines`}</h2>
        <ul>
          <li parentName="ul">{`Keep status text short, direct, and preferably a single word. Keep in mind that Status Indicator
containers can stretch to screen or container margins before truncation begins.`}</li>
          <li parentName="ul">{`Avoid creating status labels that require more characters than the container allows.`}</li>
          <li parentName="ul">{`Status Indicator text should never wrap.`}</li>
          <li parentName="ul">{`Use status labels that are relatable or familiar to your user and the task at hand.`}</li>
        </ul>
      </TabPanel>
    </InternalContent>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      